import React from 'react'
import './Buttons.css'

export function LoginGiltzaButton({ action, lang, translations }) {
    return (
        <button
            aria-label="Log-in"
            className="button-no-style"
            id="giltzaLoginButton"
            onClick={action}
            tabIndex="0"
            title="Log-in"
            type="button"
        >
            <i
                title={translations.user_login_button[lang]}
                className="fa fa-sign-in"
                aria-hidden="true"
            />
        </button>
    )
}
